import React, { Component } from 'react';
import Moment from 'moment';
import _ from 'underscore';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faBackward } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-awesome-modal';
import { Dropdown } from 'semantic-ui-react';
import moment from 'moment'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { dealBALService } from '../../bal/deal.bal';
import { dealDashboardBALService } from '../../bal/Deal-Dashboard/dealDashboard.bal';

import SideDrawer from './components/sideDrawer.js';
import DealOverview from './components/dealOverview'
import DealRisk from './components/dealRisk'
import HistoricalCashflow from './historicalCashflow'
import ProjectedCashflow from './projectedCashflow'
import DealTrades from './dealTrades'
import DealMarks from './dealMarks'
import DealCreditMetrics from './components/dealCreditMetrics';
import DealPerformanceOverview from './dealPerformanceOverview';
import ToastMessage from "./../deals/components/toastMessage";

import { torGridBALService } from '../../bal/torGridBALService.bal';

class DealDashboard extends Component {
  constructor(props) {
    super(props);

    this.mounted = false;
    this.timeout = 250;
    this.state = {
      GridId: "dealDashboard",
      drawerOpen: false,
      GridTitle: 'Exposure > Overview',
      display: "none",
      thetaChartData: [],
      tabindex: 5,
      chartMaturityData: [],
      chartVintageData: [],
      maturityLabel: [],
      vintageLabel: [],
      AsOfDate: null,
      Frequency: 'm',
      gridData: [],
      filterData: [],
      dateList: [],
      Deal: null,
      DealName: null,
      fund: "All",
      MessageBoxBackground: "Red",
      fundList: [
        { key: 0, text: "TACF", value: "TOR" },
        { key: 1, text: "TACO", value: "TACO" },
        { key: 2, text: "TACO II", value: "TACO II" },
        { key: 3, text: "TACO III", value: "TACO III" },
        { key: 4, text: "TIFO", value: "TIFO" },
        { key: 5, text: "TALC", value: "TALC" },
        { key: 6, text: "ALL", value: "All" }
      ],
      dealList: [],
      cashflowDealList: [],
      FrequencyList: [
        { key: "m", text: "Monthly", value: "m" }
        , { key: "d", text: "Daily", value: "d" }
        , { key: "w", text: "Weekly", value: "w" }
        , { key: "q", text: "Quaterly", value: "q" }
      ],
      chartData: [],
      chartDataRisk: [],
      quarterList: [],
      cashflowQuarter: [],
      filterQuarterList: [],
      selectedQuarter: null,
      creditMetrics: {},
      filterCreditMetrics: [],
      modalVisible: false,
      modalType: 'close',
      modalHeading: '',
      modalHandler: '',
    }

    dealBALService.getAllDeals().then(data => {
      this.setState({ dealList: data });
    }, error => { console.log(error); });

    dealDashboardBALService.getAllCashflowDeals().then(data => {
      this.setState({ cashflowDealList: data });
    }, error => { console.log(error); });

    this.handleCloseErrorBox = this.handleCloseErrorBox.bind(this);
    this.refresh = this.refresh.bind(this);
    this.refreshClicked = this.refreshClicked.bind(this);

    this.handleDropDownChangeFrequency = this.handleDropDownChangeFrequency.bind(this);
    this.handleChangeAsOfDate = this.handleChangeAsOfDate.bind(this);

    this.handleDropDownChangeFund = this.handleDropDownChangeFund.bind(this);
    this.handleDropDownChangeDeal = this.handleDropDownChangeDeal.bind(this);
    this.handleDropDownChangeDealDates = this.handleDropDownChangeDealDates.bind(this);
    this.filterData = this.filterData.bind(this);
    this.filterDate = this.filterDate.bind(this);
    this.toggleTabIndex = this.toggleTabIndex.bind(this);
    this.drawerToggleClickHandler = this.drawerToggleClickHandler.bind(this);
    this.handleGridTitle = this.handleGridTitle.bind(this);
    this.closeErrorMessage = this.closeErrorMessage.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleModalChange = this.handleModalChange.bind(this);
    this.closeMessage = this.closeMessage.bind(this);

    dealDashboardBALService.getDealQuarterlyDates(null).then(data => {
      this.setState({ quarterList: data });
    });
  }

  componentDidMount = async () => {
    await torGridBALService.getRowData('hedgeReport', { query: 'Exec [sp_get_quarterly_dates] 0' }).then(data => {
      this.setState({ dateList: data });
      this.filterDate(data, data[0]['report date'], new Date(moment(data[0]['report date'], "YYYYMMDD").format("YYYY-MM-DD")));
    });
    this.mounted = true;
  }

  filterDate(data, date, formatDate) {
    const frequencyList = data.filter(function (item) {
      return item['report date'] === date
    });

    this.setState({ AsOfDate: formatDate, Frequency: frequencyList[0]['for frequency'] });
  }

  render() {
    return this.getTemplate();
  }

  handleChangeAsOfDate = date => {
    const {dateList} = this.state;
    if(date) {
      const frequencyList = dateList.filter(function (item) {
        return item['report date'] === parseInt(moment(date).format("YYYYMMDD"))
      });

      if(frequencyList?.length > 0 && frequencyList[0]) {
        const asOfDate = moment(date).format("YYYY-MM-DD");
        this.setState({ AsOfDate: asOfDate }, async () => {
          await this.filterDate(this.state.dateList, parseInt(moment(date).format("YYYYMMDD")), moment(date).format("YYYY-MM-DD"));
          this.refresh();
        });

        if (this.state.gridData && this.state.gridData.length > 0) {
          this.filterData(this.getFilterList(null, null, asOfDate));
        }
      } else {
        this.setState({
          AsOfDate: moment(date).format("YYYY-MM-DD")
        });
      }
    } else {
      this.setState({
        AsOfDate: null
      })
    }
    
  };

  handleDropDownChangeDealDates = (event, data) => {
    const selectedQuarter = data.value;
    this.setState({
      selectedQuarter: selectedQuarter
    });
  };

  handleDropDownChangeFrequency = (event, data) => {
    const frequency = data.value
    this.setState({
      Frequency: frequency
    });
    if (this.state.gridData && this.state.gridData.length > 0) {
      this.filterData(this.getFilterList(null, null, null, frequency));
    }
  };

  handleDropDownChangeDeal = (event, data) => {
    const Deal = data?.value;
    const DealName = this.state.dealList.filter(function (item) { return item.id === Deal }).map(item => { return item.name });

    if (DealName && DealName.length > 0) {
      const filterQuarterList = this.state.quarterList.filter(item => item.Deal === DealName[0]).map(
        item => {
          return {
            key: item.Quarter, text: item.Quarter, value: item.Quarter
          }
        }
      );

      this.setState({
        Deal: Deal,
        fund: 'All',
        DealName: DealName,
        gridData: [],
        filterData: [],
        chartData: [],
        chartDataRisk: [],
        filterQuarterList: filterQuarterList
      }, () => {
        dealBALService.getAllFunds(Deal).then(data => {
          for (var i = 0; i < data.length; i++) {
            data[i]['key'] = data[i]['id']
            data[i]['text'] = (data[i]['fundName'] === 'TOR' ? 'TACF' : data[i]['fundName'])
            data[i]['value'] = data[i]['fundName']
            delete (data[i]['id']);
            delete (data[i]['fundName']);
          }
          data.push({ key: 4, text: "ALL", value: "All" });

          this.setState({
            fundList: data
          });
        }, error => {
          console.log(error);
        });

        this.refresh(this.state.tabindex);
      });
    }
  };

  handleDropDownChangeFund = (event, data) => {
    const Fund = data.value
    this.setState({
      fund: Fund
    });
    if (this.state.tabindex === 2) {
      this.filterData([{ colName: 'Fund', value: Fund }])
    } else if (this.state.tabindex === 5 || this.state.tabindex === 6) {
      this.filterData([{ colName: 'fund', value: Fund }])
    } else {
      this.filterData(this.getFilterList(null, Fund));
    }
  };

  handleCloseErrorBox = () => {
    this.setState({ display: "none" });
  }

  filterData(filterList) {
    var data = this.state.gridData;

    if (filterList) {
      filterList.forEach(filter => {
        const { colName, value } = filter;
        if (value !== 'All') {
          data = data.filter(function (item) {
            return item[colName] === value
          });
        }
      });
    }

    this.setState({ filterData: data });
  }



  getFilterList(deal, fundName, asOfDate, frequency) {
    const { DealName, fund, AsOfDate, Frequency } = this.state;

    asOfDate = asOfDate ?? AsOfDate;
    frequency = frequency ?? Frequency;

    if (DealName && DealName.length > 0) {
      return (
        [
          { colName: 'fund', value: fundName ?? fund },
          { colName: 'deal', value: deal ?? DealName[0] },
          { colName: 'for frequency', value: frequency },
          { colName: 'report date', value: asOfDate ? Moment(asOfDate).format('YYYYMMDD') : '' }
        ]);
    }
    return (
      [
        { colName: 'fund', value: fundName ?? fund },
        { colName: 'deal', value: deal ?? '' },
        { colName: 'for frequency', value: frequency },
        { colName: 'report date', value: asOfDate ? Moment(asOfDate).format('YYYYMMDD') : '' }
      ]);
  }

  refreshClicked() {
    this.refresh(null);
  }

  refresh(tabindexparam) {
    const { tabindex, AsOfDate, Frequency, DealName, fund, creditMetrics, selectedQuarter } = this.state;
    tabindexparam = tabindexparam ?? tabindex;

    if (tabindexparam === 5 && DealName && DealName[0] && Frequency && AsOfDate) {
      dealDashboardBALService.getPositionsData({ AsOfDate: AsOfDate, Frequency: Frequency, Deal: DealName[0], Fund: 'All' }).then(data => {
        this.setState({ gridData: data }, () => {
          this.filterData([{ colName: 'fund', value: fund }]);
        });
      }, error => {
        this.setState({
          showMessage: true,
          errorList: [error],
          messageHeader: "Error while loading the data :",
          modalVisible: true,
          modalType: 'close',
          modalHandler: 'save',
          modalHeading: 'Error while loading the data'
        });
        console.log(error);
      });

      dealDashboardBALService.getMVHistory({ Deal: DealName[0], Fund: fund }).then(data => {
        this.setState({ chartData: data });
      }, error => {
        this.setState({
          showMessage: true,
          errorList: [error],
          messageHeader: "Error while loading the data :",
          modalVisible: true,
          modalType: 'close',
          modalHandler: 'save',
          modalHeading: 'Error while loading the data'
        });
        console.log(error);
      });
    } else if (tabindexparam === 6 && DealName && DealName[0]) {
      dealDashboardBALService.getPositionsData({ AsOfDate: '', Frequency: Frequency, Deal: DealName[0], Fund: fund }).then(data => {
        var groups = _.groupBy(data, 'report date');
        var chartdata = _.map(groups, function (value, key) {
          var groups2 = _.groupBy(value, 'fund');
          var output = _.map(groups2, function (value2, key2) {
            return {
              'report date': [key.slice(0, 4), "-", key.slice(4, 6), "-", key.slice(6, 8)].join(''),
              'fund': key2,
              'asset stress pnl': _.reduce(value2, function (total, o) { return total + o['asset stress pnl']; }, 0),
              'ir 100bp': _.reduce(value2, function (total, o) { return total + o['ir 100bp']; }, 0),
              'cs 100bp': _.reduce(value2, function (total, o) { return total + o['cs 100bp']; }, 0),
              'cswpct_100': _.reduce(value2, function (total, o) { return total + o['cswpct_100']; }, 0),
            }
          })
          return output;
        });
        this.setState({ gridData: data, chartDataRisk: chartdata.flat() });
        this.filterData([{ colName: 'fund', value: fund }]);
      }, error => {
        this.setState({
          showMessage: true,
          errorList: [error],
          messageHeader: "Error while loading the data :",
          modalVisible: true,
          modalType: 'close',
          modalHandler: 'save',
          modalHeading: 'Error while loading the data'
        });
        console.log(error);
      });

    } else if (tabindexparam === 2) {
      this.setState({ isOpenLoad: true });
      torGridBALService.getRowData('hedgeReport', { query: `Exec [sp_get_pnl_deal] '${DealName}', 'm', '${moment(this.state.AsOfDate).format("YYYYMMDD")}'` }).then(data => {
        this.setState({ gridData: data });
        this.filterData([{ colName: 'Fund', value: 'All' }]);
        this.setState({ isOpenLoad: false });
      }, error => {
        this.setState({
          showMessage: true,
          errorList: [error],
          messageHeader: "Error while loading the data :",
          modalVisible: true,
          modalType: 'close',
          modalHandler: 'save',
          modalHeading: 'Error while loading the data'
        });
        console.log(error);
      });
    } else if (tabindexparam === 7) {
      if (DealName && creditMetrics[DealName[0]]) {
        this.setState({ filterCreditMetrics: creditMetrics[DealName[0]].filter(item => item.Quarter === selectedQuarter) })
      } else if (DealName) {
        dealDashboardBALService.getCreditMetrics({ Deal: DealName[0], Quarter: 'ALL' }).then(data => {
          let creditMetricsArray = this.state.creditMetrics;
          creditMetricsArray[DealName[0]] = data;
          this.setState({ 
            filterCreditMetrics: data.filter(item => item.Quarter === selectedQuarter),
            creditMetrics: creditMetricsArray
          });
        }, error => {
          this.setState({
            showMessage: true,
            errorList: [error],
            messageHeader: "Error while loading the data :",
            modalVisible: true,
            modalType: 'close',
            modalHandler: 'save',
            modalHeading: 'Error while loading the data'
          });
          console.log(error);
        });
      }
    }
  }

  drawerToggleClickHandler = () => {
    this.setState({
      drawerOpen: !this.state.drawerOpen
    })
  }

  handleGridTitle = (val) => {
    var gridTitle = ''
    if (val === 2) {
      gridTitle = 'Performance > PNL'
    } else if (val === 3) {
      gridTitle = 'Performance > Cashflows'
    } else if (val === 4) {
      gridTitle = 'Performance > Projection'
    } else if (val === 8) {
      gridTitle = 'Performance > Trades'
    } else if (val === 5) {
      gridTitle = 'Exposure > Overview'
    } else if (val === 6) {
      gridTitle = 'Exposure > Risk'
    } else if (val === 7) {
      gridTitle = 'Exposure > Credit Metrics'
    } else if (val === 9) {
      gridTitle = 'Exposure > Mark History'
    }

    this.setState({
      GridTitle: gridTitle
    })
  }

  toggleTabIndex = async (val) => {
    if (this.state.tabindex !== val) {
      if (val === 3) {
        await dealDashboardBALService.getCashflowQuarterlyDates(null).then(data => {
          this.setState({ cashflowQuarter: data, selectedQuarter: data[0]?.Quarter });
        });
      } else if (val === 4) {
        await dealDashboardBALService.getProjectedCashflowQuarterlyDates(null).then(data => {
          this.setState({ cashflowQuarter: data, selectedQuarter: data[0]?.Quarter });
        });
      } else if (val === 2) {
        await torGridBALService.getRowData('hedgeReport', { query: 'Exec [sp_get_quarterly_dates] 1' }).then(data => {
          this.setState({ dateList: data });
          this.filterDate(data, data[0]['report date'], moment(data[0]['report date'], "YYYYMMDD").format("YYYY-MM-DD"));
        });
      } else if ((val === 5 || val === 6) && this.state.tabindex !== 5 && this.state.tabindex !== 6) {
        await torGridBALService.getRowData('hedgeReport', { query: 'Exec [sp_get_quarterly_dates] 0' }).then(data => {
          this.setState({ dateList: data });
          this.filterDate(data, data[0]['report date'], moment(data[0]['report date'], "YYYYMMDD").format("YYYY-MM-DD"));
        });
      }
      this.setState({
        tabindex: val,
        drawerOpen: false
      })
      this.handleGridTitle(val);
      this.refresh(val);
    }
  }

  closeModal() {
    if (this.state.modalHandler === 'refresh') {
      window.location.reload();
    }
    this.setState({
      modalVisible: false
    });
  }

  handleModalChange() {
    if (this.state.modalHandler === 'refresh') {
      this.setState({ isOpenGrid: true, modalVisible: false });
    } else if (this.state.modalHandler === 'save') {
      this.saveDB();
      this.setState({ isOpenGrid: true, modalVisible: false });
    } else {
      this.setState({ isOpenGrid: true, modalVisible: false });
    }
  }

  closeErrorMessage() {
    this.setState({ showMessage: false, errorList: [] });
  }

  closeMessage() {
    this.setState({ showMessage: false, messageHeader: "" });
  }


  getTemplate() {
    const { cashflowQuarter, MessageBoxBackground, filterCreditMetrics, filterQuarterList, selectedQuarter, chartData, chartDataRisk, GridTitle, AsOfDate, Deal, filterData, DealName, fund, fundList, error, display, drawerOpen, tabindex, modalHeading } = this.state;
    const dealList = this.state.dealList.map(item => { return { key: item.name, value: item.id, text: item.name } });
    const cashflowDealList = this.state.cashflowDealList.map(item => { return { key: item.name, value: item.id, text: item.name } });
    const cashflowQuarterList = cashflowQuarter.map(item => {
      return { key: item.Quarter, value: item.Quarter, text: item.Quarter }
    });

    const includeDate = this.state.dateList.map(item => {
      return new Date(moment(item['report date'], "YYYYMMDD").format("YYYY-MM-DD"))
    });
    return (
      <div style={{ gridTemplateRows: 'auto auto auto 1fr auto', display: 'grid', height: '100%', width: '100%', position: 'relative', resizable: true }}>
        {
          this.state.showMessage ?
            <ToastMessage
              header={this.state.messageHeader}
              errorList={this.state.errorList}
              closeMessage={this.closeErrorMessage}
            /> : <div></div>
        }
        <SideDrawer show={drawerOpen} toggleTabIndex={this.toggleTabIndex} tabindex={tabindex} toggleDrawer={this.drawerToggleClickHandler} />
        <div className="content-container" style={{ height: "100%", width: '100%', border: "solid 1px #babfc7", padding: "0px 0px 0px 0px" }}>
          <div style={{ float: "left", padding: "14px 6px 10px 16px", cursor: "pointer" }} onClick={this.drawerToggleClickHandler} >
            <FontAwesomeIcon icon={faBackward} />
          </div>
          {
            <div >
              <div style={{ display: (GridTitle && GridTitle !== '') ? "inline" : "none", float: "left", height: "50px", borderRight: "solid 1px #babfc7", position: "relative", padding: "14px 10px 10px 10px" }} onClick={this.drawerToggleClickHandler}>
                <b>{GridTitle}</b>
              </div>
              <div style={{ float: "left", height: "50px", padding: "14px 10px 10px 10px", cursor: "pointer" }}>
                Deal:
              </div>
              <div style={{ float: "left", height: "50px", padding: "5px 0px 0px 10px", cursor: "pointer", minWidth: "200px" }}>
                <Dropdown
                  placeholder='Select Deal'
                  name="Deal"
                  fluid
                  search
                  selection
                  onChange={this.handleDropDownChangeDeal}
                  options={tabindex === 4 ? cashflowDealList : dealList}
                  value={Deal}
                  className={'form-control'}
                />
              </div>
              {
                tabindex === 7
                  ?
                  null
                  :
                  <div style={{ float: "left", height: "50px", padding: "14px 10px 10px 10px", cursor: "pointer" }}>
                    Fund Filter:
                  </div>
              }
              {
                tabindex === 7
                  ?
                  null
                  :
                  <div style={{ float: "left", height: "50px", padding: "5px 0px 0px 10px", cursor: "pointer", minWidth: "200px" }}>
                    <Dropdown
                      placeholder='Select Fund'
                      name="fund"
                      fluid
                      search
                      selection
                      onChange={this.handleDropDownChangeFund}
                      options={fundList}
                      value={fund}
                      className={'form-control'}
                    />
                  </div>
              }
              <div style={{ float: "right", display: display, background: { MessageBoxBackground } }} className="alert alert-danger alert-dismissible fade show" role="alert">
                <span>{error}</span>
                <span className="close" data-dismiss="alert" aria-label="Close" style={{ cursor: "pointer" }} onClick={this.handleCloseErrorBox}>
                  <span aria-hidden="true">&times;</span>
                </span>
              </div>
              {
                <Collapse isOpen={true} style={{ float: "right", height: "auto" }}>
                  <div style={{ float: "right", padding: "14px 10px 10px 10px", cursor: "pointer" }}>
                    <FontAwesomeIcon onClick={this.refreshClicked} icon={faSync} />
                  </div>
                  {
                    tabindex === 7 || tabindex === 3 || tabindex === 4
                      ?
                      <div style={{ float: "right", height: "50px", padding: "5px 0px 0px 10px", cursor: "pointer", minWidth: "150px" }}>
                        <Dropdown
                          placeholder='Select Quarter'
                          name="Quarter"
                          fluid
                          search
                          selection
                          onChange={this.handleDropDownChangeDealDates}
                          options={tabindex === 7 ? filterQuarterList : cashflowQuarterList}
                          value={selectedQuarter}
                          className={'form-control'}
                        />
                      </div>
                      : <font>
                        <div style={{ float: "right", height: "50px", padding: "14px 10px 10px 10px", cursor: "pointer", minWidth: "150px" }}>
                          <DatePicker
                            dateFormat="MM/dd/yyyy"
                            name="AsOfDate"
                            selected={AsOfDate ? new Date(AsOfDate) : ''}
                            onChange={this.handleChangeAsOfDate}
                            includeDates={includeDate}
                            autoComplete="off"
                            showYearDropdown
                            showMonthDropdown
                          />
                        </div>
                      </font>
                  }
                  <div style={{ float: "right", height: "50px", padding: "14px 10px 10px 10px", cursor: "pointer" }}>
                    As Of Date :
                </div>
                </Collapse>
              }
            </div>
          }
        </div>

        <div className="scrollForm">
          {
            tabindex === 2
              ?
              <DealPerformanceOverview
                Fund={fund}
                Deal={DealName ? DealName[0] : ''}
                AsOfDate={AsOfDate}
                gridData={filterData}
                chartData={chartDataRisk}
              />
              :
              tabindex === 3
                ?
                <HistoricalCashflow Deal={DealName ? DealName[0] : ''} AsOfDate={selectedQuarter} fund={fund} />
                :
                tabindex === 4
                  ?
                  <ProjectedCashflow Deal={DealName ? DealName[0] : ''} AsOfDate={selectedQuarter} fund={fund} />
                  :
                  tabindex === 5
                    ?
                    <DealOverview
                      Fund={fund}
                      Deal={DealName ? DealName[0] : ''}
                      AsOfDate={AsOfDate}
                      gridData={filterData}
                      chartData={chartData}
                    />
                    :
                    tabindex === 6
                      ?
                      <DealRisk
                        Fund={fund}
                        Deal={DealName ? DealName[0] : ''}
                        AsOfDate={AsOfDate}
                        gridData={filterData}
                        chartData={chartDataRisk}
                      />
                      :
                      tabindex === 7 && filterCreditMetrics && filterCreditMetrics.length > 0
                        ?
                        <DealCreditMetrics
                          Deal={DealName ? DealName[0] : ''}
                          AsOfDate={AsOfDate}
                          chartData={filterCreditMetrics}
                        />
                        :
                        tabindex === 8
                          ?
                          <DealTrades Deal={DealName} AsOfDate={AsOfDate} fund={fund} />
                          :
                          tabindex === 9
                            ?
                            <DealMarks Deal={Deal} AsOfDate={AsOfDate} fund={fund} />
                            :
                            null
          }
        </div>
        <div>
          <section>
            {
              this.state.modalType === 'close'
                ?
                <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                  <div className="p20 centerElem" style={{ width: "350px", height: "220px" }}>
                    <h1>{modalHeading}</h1>
                    <button className="graybtnDes mt100" onClick={() => this.closeModal()}>Close</button>
                  </div>
                </Modal>
                :
                this.state.modalType === 'saveChanges'
                  ?
                  <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                    <div className="p20 centerElem" style={{ width: "420px", height: "270px" }}>
                      <h1>{modalHeading}</h1>
                      <button className="btnDes mt100" onClick={() => this.handleModalChange()}>Yes</button>
                      <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                    </div>
                  </Modal>
                  :
                  <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                    <div className="p20 centerElem" style={{ width: "350px", height: "220px" }}>
                      <h1>{modalHeading}</h1>
                      <button className="btnDes mt100" onClick={() => this.handleModalChange()}>Yes</button>
                      <button className="graybtnDes mt100 ml20" onClick={() => this.closeModal()}>No</button>
                      <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                    </div>
                  </Modal>
            }
          </section>
        </div>
      </div>
    );
  }
}
export default DealDashboard;