import * as moment from 'moment';
import { Utils } from '../helper/utils';
import { userBALService } from './user.bal';
const {config} = require('../config');

const dateFormatter = (params) => {

    return moment(params.value).utcOffset(0, false).format(params.dateFormat ?? 'YYYY-MM-DD hh:mm:ss');
  
    //return moment(params.value)?.format(params.dateFormat ?? 'YYYY-MM-DD');
  }
const getColumnList = async (params) => {
    return  {
        'columnSpecs': [
            {displayIndex: 1, 'display': 'Deal Pipeline Summary Id', 'col': 'dealPipeSummaryId','ag-grid': true, hide:true, width: 200 },
            {displayIndex: 2, 'display': 'Project Name', 'col': 'projectName','ag-grid': true, width: 200 },
            {displayIndex: 3, 'display': 'Analysts', 'col': 'analystNames','ag-grid': true, width: 300 },
            {displayIndex: 4, 'display': 'Asset Type', 'col': 'assetType', 'ag-grid': true, width: 160},
            {displayIndex: 5, 'display': 'Public/Private', 'col': 'publicPrivate', 'ag-grid': true, width: 150},
            {displayIndex: 6, 'display': 'Credit Ranking', 'col': 'creditRanking', 'ag-grid': true, width: 160},
            {displayIndex: 7, 'display': 'Status', 'col': 'status', 'ag-grid': true, width: 160},
            {displayIndex: 8, 'display': 'Expected Execution Date', 'col': 'expectedExecutionDate', 'ag-grid': true, width: 180},
            {displayIndex: 9, 'display': 'Country of Risk', 'col': 'countryOfRisk', 'ag-grid': true, width: 150},
            {displayIndex: 10, 'display': 'GICS Sector', 'col': 'gicsSector','ag-grid': true, width: 200 },
            {displayIndex: 11, 'display': 'Purchase/Issue Price', 'col': 'purchaseIssuePrice', 'ag-grid': true, width: 180},
            {displayIndex: 12, 'display': 'Cash Coupon', 'col': 'cashCoupon', 'ag-grid': true, width: 140},
            {displayIndex: 13, 'display': 'PIK Coupon', 'col': 'pikCoupon', 'ag-grid': true, width: 140},
            {displayIndex: 14, 'display': 'Equity Upside', 'col': 'equityUpside', 'ag-grid': true, width: 150},
            {displayIndex: 15, 'display': 'Expected Tenor (Years)', 'col': 'expectedTenor', 'ag-grid': true, width: 140},
            {displayIndex: 16, 'display': 'Expected TOR Size ($Millions)', 'col': 'expectedTorSize', 'ag-grid': true, width: 140},
            {displayIndex: 17, 'display': 'Total Deal Size($Millions)', 'col': 'totalDealSize', 'ag-grid': true, width: 140},
            {displayIndex: 18, 'display': 'Expected IRR (Unlevered)', 'col': 'expectedIRR', 'ag-grid': true, width: 200},
            {displayIndex: 19, 'display': 'Comment', 'col': 'comment', 'ag-grid': true, width: 400},
            {displayIndex: 20, 'display': 'Created Date', 'col': 'createdDate', cellRenderer: dateFormatter , 'ag-grid': true, width: 170},
            {displayIndex: 21, 'display': 'Created By', 'col': 'createdBy', 'ag-grid': true, width: 150},
            {displayIndex: 22, 'display': 'Last Updated Date', 'col': 'lastUpdatedDate', cellRenderer: dateFormatter, 'format': 'dateFormat', 'ag-grid': true, width: 170},
            {displayIndex: 23, 'display': 'Last Updated By', 'col': 'lastUpdatedBy', 'ag-grid': true, width: 150},
            {displayIndex: 24, 'display': 'Created By Id', hide:true, 'col': 'createdById', 'ag-grid': true, width: 200},
            {displayIndex: 25, 'display': 'Last Updated By Id', hide:true, 'col': 'LastUpdatedById', 'ag-grid': true, width: 200},
        ]};
}

export const dealPipelineSummaryBALService = {
    getColumnList,
    getRowData,
    getAutoGroupColumnDef,
    save
};

function getAutoGroupColumnDef(params) {
    return {
        headerName: 'Group',
        field: 'deal',
        width: 600,
        pinned: 'left',
      };    
}

function getRowData(params) {
    const requestOptions = { method: 'GET', headers: userBALService.getAuthHeader() };
    return fetch(`${config.apiDDSUrl}/dealPipeSummary/getAll`, requestOptions).then(Utils.handleResponse);
}

function save(row) {
    /*if(!(row.dealPipeSummaryId > 0)) {
        row = Utils.removeBlanksNotZero(row);
    }*/
    return fetch(`${config.apiDDSUrl}/addDealPipeSummary`, { body: JSON.stringify(row), method: 'PUT', headers: { ...userBALService.getAuthHeader(), 'Content-Type': 'application/json' } }).then( data => { return Utils.handleResponse(data) });
}
