import './../../App.css'
import ModalForm from '../common/modalForm'
import { userBALService } from '../../bal/user.bal'
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import ToastMessage from "../deals/components/toastMessage";
import { Prompt } from "react-router-dom";
import { dealPipelineSummaryBALService } from '../../bal/dealPipelineSummary.bal';
import { Dropdown } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSave } from "@fortawesome/free-solid-svg-icons";

import { dealBALService } from '../../bal/deal.bal';

class DealPipelineSummary extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;

        const today = new Date();
        const yesterday = new Date(today);

        yesterday.setDate(yesterday.getDate() - 14);
        const dealPipeline = this.props?.location?.state?.data;

        const user = userBALService.getUserDetails();
        const userId = user?.user?.user?.id;

        const isIRUser = user?.user?.userRoles?.filter(item => item && item.toLowerCase() === 'ir')?.length > 0;
        const isReadOnly = dealPipeline && dealPipeline?.leadAnalystId !== userId && dealPipeline?.createdById !== userId && dealPipeline?.analyst2Id !== userId && dealPipeline?.analyst3Id !== userId && dealPipeline?.analyst4Id !== userId;
        const roles = user?.user?.userRoles?.filter(item => item && (item.toLowerCase() === 'risk' || item.toLowerCase() === 'ir' || item.toLowerCase() === 'pm' || item.toLowerCase() === 'partner'));
        const isAllRole = roles?.length > 0;
        this.state = {
            ...user,
            isReadOnly: isReadOnly,
            isIRUser: isIRUser,
            isAllRole: isAllRole,
            rowData: [],
            id: null,
            isOpenGrid: true,
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "",
            showMessage: false,
            gridData: [],
            editType: 'fullRow',
            editorState: null,
            existingDealPipeSummary: dealPipeline ?? {
                dealPipeSummaryId: null,
                projectName: null,
                gicsSectorId: null,
                gicsIndustryGroupId: null,
                gicsIndustryId: null,
                gicsSubIndustryId: null,
                gicsNACEId: null,
                bicsSectorId: null,
                bicsIndustryGroupId: null,
                bicsIndustryId: null,
                bicsSubIndustryId: null,
                countryOfRiskId: null,
                isPublic: null,
                equityUpside: null,
                equityUpsideForm: null,
                assetTypeId: null,
                creditRankingId: null,
                statusId: null,
                expectedExecutionDate: null,
                purchaseIssuePrice: null,
                cashCoupon: null,
                pikCoupon: null,
                contractualMaturity: null,
                expectedTenor: null,
                currency: null,
                totalDealSizeLow: null,
                totalDealSizeHigh: null,
                totalDealSize: null,
                expectedTorSizeLow: null,
                expectedTorSizeHigh: null,
                expectedTorSize: null,
                isin: null,
                comment: null,
                lastUpdatedById: null,
                leadAnalystId: null,
                analyst2Id: null,
                analyst3Id: null,
                analyst4Id: null,
                expectedIRR: null
            },
            newDealPipeSummary: dealPipeline ?? {
                dealPipeSummaryId: null,
                projectName: null,
                gicsSectorId: null,
                gicsIndustryGroupId: null,
                gicsIndustryId: null,
                gicsSubIndustryId: null,
                gicsNACEId: null,
                bicsSectorId: null,
                bicsIndustryGroupId: null,
                bicsIndustryId: null,
                bicsSubIndustryId: null,
                countryOfRiskId: null,
                isPublic: null,
                equityUpside: null,
                equityUpsideForm: null,
                assetTypeId: null,
                creditRankingId: null,
                statusId: null,
                expectedExecutionDate: null,
                purchaseIssuePrice: null,
                cashCoupon: null,
                pikCoupon: null,
                contractualMaturity: null,
                expectedTenor: null,
                currency: null,
                totalDealSizeLow: null,
                totalDealSizeHigh: null,
                totalDealSize: null,
                expectedTorSizeLow: null,
                expectedTorSizeHigh: null,
                expectedTorSize: null,
                isin: null,
                comment: null,
                lastUpdatedById: null,
                leadAnalystId: null,
                analyst2Id: null,
                analyst3Id: null,
                analyst4Id: null,
                expectedIRR: null
            },
            analystListRaw: [],
            gicsSectorListRaw: [],
            gicsIndustryGroupListRaw: [],
            gicsIndustryListRaw: [],
            gicsSubIndustryListRaw: [],
            naceListRaw: [],
            gicsSectorList: [],
            gicsIndustryGroupList: [],
            gicsIndustryList: [],
            gicsSubIndustryList: [],
            naceList: [],
            bicsSectorList: [],
            bicsIndustryGroupList: [],
            bicsIndustryList: [],
            bicsSubIndustryList: [],
            countryList: [],
            assetTypeList: [],
            creditRankingList: [],
            statusList: [],
            currencyList: [],
            values: null,
            selectedRows: null,
            showAll: false
        };
        this.render = this.render.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.getPage = this.getPage.bind(this);
        this.saveDB = this.saveDB.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.handleChangeTextBox = this.handleChangeTextBox.bind(this);
        this.goHome = this.goHome.bind(this);
        //this.handleModalChange = this.handleModalChange.bind(this);
        //this.togglePanel = this.togglePanel.bind(this);
        this.handleChangeNew = this.handleChangeNew.bind(this);
        this.getSettingByName = this.getSettingByName.bind(this);
        this.loadSettings = this.loadSettings.bind(this);

        dealBALService.getNace().then(data => { this.setState({ naceListRaw: data, naceList: [] }); }, error => { console.log(error); });
        dealBALService.getNaceActivity().then(data => { this.setState({ activityListRaw: data, activityList: [] }); }, error => { console.log(error); });
        dealBALService.getAnalysts().then(data => { this.setState({ analystList: data.map(item => { return { key: item.name, value: item.id, text: item.name } }) }); }, error => { console.log(error); });
        dealBALService.getAllCountry().then(data => { this.setState({ countryList: data.map(item => { return { key: item.id, value: item.id, text: item.name } }) }); }, error => { console.log(error); });
        dealBALService.getGics().then(data => {
            const gicsSectorList = data.map(item => { return { key: item.name, value: item.id, text: item.name } });
            const { gicsSectorId, gicsIndustryGroupId, gicsIndustryId, gicsSubIndustryId, gicsNACEId } = this.state?.newDealPipeSummary;
            this.setState({
                gicsSectorListRaw: data,
                gicsSectorList: gicsSectorList
            });
            if (gicsSectorId > 0) {
                this.handleDropDownChange(null, { name: "gicsSectorId", value: gicsSectorId });
            }
            if (gicsIndustryGroupId > 0) {
                this.handleDropDownChange(null, { name: "gicsIndustryGroupId", value: gicsIndustryGroupId });
            }
            if (gicsIndustryId > 0) {
                this.handleDropDownChange(null, { name: "gicsIndustryId", value: gicsIndustryId });
            }
            if (gicsSubIndustryId > 0) {
                this.handleDropDownChange(null, { name: "gicsSubIndustryId", value: gicsSubIndustryId });
            }
            if (gicsNACEId > 0) {
                this.handleDropDownChange(null, { name: "gicsNACEId", value: gicsNACEId });
            }
        },
            error => { console.log(error); });
        this.loadSettings();
    }

    goHome = async () => {
        this.setState({ redirectTo: 'dealPipelineDashboard' });
    }

    getFormatted = (value) => {
        return value && !isNaN(value) && parseFloat(value) ? parseFloat(value).toFixed(Number.isInteger(value) ? 0 : 2) : value;
    }

    render() {
        if (this.state.redirectTo)
            return <Redirect to={this.state.redirectTo} />;
        return this.getPage();
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    handleChangeTextBox(e) {
        const { name, value } = e.target;
        this.setState({
            newDealPipeSummary: { ...this.state.newDealPipeSummary, [name]: value }
        });
    }

    handleDropDownChange(e, { name, value }) {
        if (name === "gicsSectorId") {
            if (value) {
                var industryGroupsRaw = this.state.gicsSectorListRaw.filter(function (item) { return item.id === value })[0]?.industryGroups;
                this.setState({
                    gicsIndustryGroupListRaw: industryGroupsRaw,
                    gicsIndustryGroupList: industryGroupsRaw.map(item => { return { key: item.name, value: item.id, text: item.name } }),
                    gicsIndustryListRaw: [],
                    gicsIndustryList: [],
                    gicsSubIndustryListRaw: [],
                    gicsSubIndustryList: [],
                    naceList: [],
                    newDealPipeSummary: { ...this.state.newDealPipeSummary, [name]: value, gicsIndustryGroupId: null, gicsIndustryId: null, gicsSubIndustryId: null, gicsNACEId: null }
                });
            } else {
                this.setState({
                    gicsIndustryGroupListRaw: [],
                    gicsIndustryGroupList: [],
                    gicsIndustryListRaw: [],
                    gicsIndustryList: [],
                    gicsSubIndustryListRaw: [],
                    gicsSubIndustryList: [],
                    naceList: [],
                    newDealPipeSummary: { ...this.state.newDealPipeSummary, [name]: value, gicsIndustryGroupId: null, gicsIndustryId: null, gicsSubIndustryId: null, gicsNACEId: null }
                });

            }
        } else if (name === "gicsIndustryGroupId") {
            if (value) {
                var industriesRaw = this.state.gicsIndustryGroupListRaw.filter(function (item) { return item.id === value })[0]?.industries;
                this.setState({
                    gicsIndustryListRaw: industriesRaw,
                    gicsIndustryList: industriesRaw.map(item => { return { key: item.name, value: item.id, text: item.name } }),
                    gicsSubIndustryListRaw: [],
                    gicsSubIndustryList: [],
                    naceList: [],
                    newDealPipeSummary: { ...this.state.newDealPipeSummary, [name]: value, gicsIndustryId: null, gicsSubIndustryId: null, gicsNACEId: null }
                });
            } else {
                this.setState({
                    gicsIndustryListRaw: [],
                    gicsIndustryList: [],
                    gicsSubIndustryListRaw: [],
                    gicsSubIndustryList: [],
                    naceList: [],
                    newDealPipeSummary: { ...this.state.newDealPipeSummary, [name]: value, gicsIndustryId: null, gicsSubIndustryId: null, gicsNACEId: null }
                });
            }
        } else if (name === "gicsIndustryId" && this.state.gicsIndustryListRaw !== undefined) {
            if (value) {
                var subIndustriesRaw = this.state?.gicsIndustryListRaw.filter(function (item) { return item.id === value })[0]?.subIndustries;
                this.setState({
                    gicsSubIndustryListRaw: subIndustriesRaw,
                    gicsSubIndustryList: subIndustriesRaw.map(item => { return { key: item.name, value: item.id, text: item.name } }),
                    naceList: [],
                    newDealPipeSummary: { ...this.state.newDealPipeSummary, [name]: value, gicsSubIndustryId: null, gicsNACEId: null }
                });
            } else {
                this.setState({
                    gicsSubIndustryListRaw: [],
                    gicsSubIndustryList: [],
                    naceList: [],
                    newDealPipeSummary: { ...this.state.newDealPipeSummary, [name]: value, gicsSubIndustryId: null, gicsNACEId: null }
                });
            }
        } else if (name === "gicsSubIndustryId" && this.state.gicsSubIndustryListRaw !== undefined) {
            var setBlank = false;
            if (value) {
                const gicsSubIndustries = this.state?.gicsSubIndustryListRaw.filter(function (item) { return item.id === value });
                if (gicsSubIndustries && gicsSubIndustries.length > 0) {
                    const code = gicsSubIndustries[0].code;
                    console.log(`Sub-Industry Code - ${code} Sub-Industry Id- ${value} `);
                    const naceListRaw = this.state?.naceListRaw.filter(function (item) { return item.code === code });
                    this.setState({
                        naceList: naceListRaw?.map(item => { return { key: item.name, value: item.id, text: item.name } }),
                        newDealPipeSummary: { ...this.state.newDealPipeSummary, [name]: value, gicsNACEId: null }
                    });
                } else {
                    setBlank = true;
                }
            } else {
                setBlank = true;
            }
            if (setBlank) {
                this.setState({
                    naceList: [],
                    newDealPipeSummary: { ...this.state.newDealPipeSummary, [name]: value, gicsNACEId: null }
                });
            }
        } else {
            this.setState({ newDealPipeSummary: { ...this.state.newDealPipeSummary, [name]: value } });
        }
    }

    handleChangeNew(name, value) {
        this.setState({
            newDealPipeSummary: {
                ...this.state.newDealPipeSummary,
                [name]: value
            }
        });
    }

    closeMessage() {
        this.setState({ showMessage: false, errorList: [], messageHeader: "" });
    }

    saveDB() {
        const { newDealPipeSummary, existingDealPipeSummary, isIRUser } = this.state;

        if (JSON.stringify(newDealPipeSummary) === JSON.stringify(existingDealPipeSummary)) {
            this.setState({
                showMessage: false,
                errorList: null,
                messageHeader: "",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'No changes to save!',
                open: false,
                deal: null
            }, () => {

            });
            return;
        }

        if (newDealPipeSummary.projectName === null || newDealPipeSummary.projectName === '') {
            this.setState({
                showMessage: false,
                errorList: null,
                messageHeader: "",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Project Name is mandatory!',
                open: false,
                deal: null
            }, () => {

            });
            return;
        }

        
        //console.log(newDealPipeSummary);
        var dealPipeSummary = newDealPipeSummary;
        if(isIRUser) {
            if (newDealPipeSummary.projectName !== existingDealPipeSummary.projectName)
                dealPipeSummary = {'id' : newDealPipeSummary.id, 'projectName':newDealPipeSummary.projectName, 'isOnlyUpdatedFields': true};
            else {                
                this.setState({
                    showMessage: false,
                    errorList: null,
                    messageHeader: "",
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'close',
                    modalHeading: 'As IR user you can only change Project Name but it is same as before',
                    open: false,
                    deal: null
                }, () => {    
                });                
            }
        }

        dealPipelineSummaryBALService.save(dealPipeSummary).then(
            data => {
                this.setState({
                    newDealPipeSummary : data,
                    existingDealPipeSummary: data,
                    showMessage: false,
                    errorList: null,
                    messageHeader: "",
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'close',
                    modalHeading: 'Saved data successfully!',
                    open: false,
                    deal: null
                }, () => {

                });
            }
            , error => {
                this.setState({
                    showMessage: true,
                    errorList: [error],
                    messageHeader: `Error while saving Deal Pipeline:`,
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'close',
                    modalHeading: 'Saving data failed!'
                });
                console.log(error);
            });
    }

    handleChangeCheckbox(e) {
        this.setState({
            newDealPipeSummary: {
                ...this.state.newDealPipeSummary,
                [e.target.name]: e.target.value === "true"
            }
        });
    }


    getSettingByName(settingList, name) {
        let setting = settingList.filter(
            function (data) {
                return data['value'] === name
            }
        )

        return setting.length > 0 ? setting[0].childSettings : [];
    }

    loadSettings() {
        dealBALService.getAllSettings().then(settingList => {
            const assetTypeList = this.getSettingByName(settingList, "Asset Type").map(item => { return { key: item.value, value: item.id, text: item.value } });
            const creditRankingList = this.getSettingByName(settingList, "Credit Ranking").map(item => { return { key: item.value, value: item.id, text: item.value } });
            const statusList = this.getSettingByName(settingList, "Deal Pipe Status").map(item => { return { key: item.value, value: item.id, text: item.value } });


            dealBALService.getRates().then(data => {
                const currencyList = data.map(item => { return { key: item.Currency, value: item.Currency, text: item.Currency } });
                this.setState({
                    settingList: settingList,
                    assetTypeList: assetTypeList,
                    creditRankingList: creditRankingList,
                    statusList: statusList,
                    currencyList: currencyList
                });
            }, error => { console.log(error); });

        }, error => { console.log(error); });
    }

    handleChange(name, value) {
        this.setState({ [name]: value });
    }

    getPage() {
        const { modalType, modalVisible, modalHeading, gridReminderData, newDealPipeSummary, existingDealPipeSummary,
            analystList, gicsSectorList, countryList, assetTypeList, creditRankingList, statusList, isReadOnly, isIRUser
        } = this.state;
        const { projectName, gicsSectorId, countryOfRiskId, equityUpside, assetTypeId, creditRankingId, statusId, expectedExecutionDate, purchaseIssuePrice, cashCoupon, pikCoupon, expectedTenor, totalDealSize, expectedTorSize, comment, leadAnalystId, analyst2Id, analyst3Id, analyst4Id, expectedIRR } = newDealPipeSummary;

        return (
            <div style={{ gridTemplateRows: 'auto auto 1fr auto', display: 'grid', height: '100%', width: '100%', overflowY: 'scroll' }} >
                {
                    this.state.showMessage ?
                        <ToastMessage
                            header={this.state.messageHeader}
                            errorList={this.state.errorList}
                            closeMessage={this.closeMessage}
                        /> : <div></div>
                }
                <div>
                    <div style={{ paddingBottom: 15, paddingTop: 10, height: '10px' }}>
                        <div style={{ float: "left", paddingLeft: "10px", cursor: "pointer" }} onClick={this.goHome}>
                            <FontAwesomeIcon icon={faHome} style={{ cursor: "pointer" }} tooltip="true" title="Home" />
                        </div>
                    </div>
                </div>
                <div style={{ overflow: 'auto', paddingTop: '15px' }}>
                    <div className="dealForm">
                        <div className="row mb10">
                            <div className="col-sm-2">
                                <label style={{ paddingTop: 10, paddingLeft: 20 }} for='projectName' className="fsBig"><b>Project Name:</b></label>
                            </div>
                            <div className="col-sm-6">
                                <input type="text"
                                    placeholder="Project Name"
                                    name="projectName"
                                    autoComplete="off"
                                    value={projectName}
                                    className={'form-control inputLightP'}
                                    onChange={this.handleChangeTextBox}
                                    disabled={isReadOnly && !isIRUser}
                                />
                            </div>
                        </div>
                        <div className="row mb10">
                            <div className='col-sm-6'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label style={{ paddingTop: 10, paddingLeft: 20 }} for='leadAnalystId' className="fsBig"><b>Lead Analyst:</b></label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Dropdown
                                            placeholder='Select Lead Analyst'
                                            name='leadAnalystId'
                                            fluid
                                            search
                                            selection
                                            clearable
                                            onChange={this.handleDropDownChange}
                                            options={analystList}
                                            value={leadAnalystId}
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label style={{ paddingTop: 10, paddingLeft: 20 }} for='analyst2Id' className="fsBig"><b>Analyst 2:</b></label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Dropdown
                                            placeholder='Select Analyst 2'
                                            name='analyst2Id'
                                            fluid
                                            search
                                            selection
                                            clearable
                                            onChange={this.handleDropDownChange}
                                            options={analystList}
                                            value={analyst2Id}
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb10">
                            <div className='col-sm-6'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label style={{ paddingTop: 10, paddingLeft: 20 }} for='analyst3Id' className="fsBig"><b>Analyst 3:</b></label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Dropdown
                                            placeholder='Select Analyst 3'
                                            name='analyst3Id'
                                            fluid
                                            search
                                            selection
                                            clearable
                                            onChange={this.handleDropDownChange}
                                            options={analystList}
                                            value={analyst3Id}
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label style={{ paddingTop: 10, paddingLeft: 20 }} for='analyst4Id' className="fsBig"><b>Analyst 4:</b></label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Dropdown
                                            placeholder='Select Analyst 4'
                                            name='analyst4Id'
                                            fluid
                                            search
                                            selection
                                            clearable
                                            onChange={this.handleDropDownChange}
                                            options={analystList}
                                            value={analyst4Id}
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb10">
                            <div className='col-sm-6'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label style={{ paddingTop: 10, paddingLeft: 20 }} for='assetTypeId' className="fsBig"><b>Asset Type:</b></label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Dropdown
                                            placeholder='Select Asset Type'
                                            name='assetTypeId'
                                            fluid
                                            search
                                            selection
                                            clearable
                                            onChange={this.handleDropDownChange}
                                            options={assetTypeList}
                                            value={assetTypeId}
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label style={{ paddingTop: 10, paddingLeft: 20 }} for='isPublic' className="fsBig"><b>Public / Private:</b></label>
                                    </div>
                                    <div className="col-sm-8" style={{ width: 470, paddingTop: 10 }} onChange={this.handleChangeCheckbox}>
                                        <input type="radio" id='public' value={true} name='public' checked={newDealPipeSummary.public === true} disabled={isReadOnly} /> <label for='public' className="fsBig"><b>Public</b></label>&nbsp;&nbsp;&nbsp;
                                        <input type="radio" id='private' value={false} name='public' checked={newDealPipeSummary.public === false} disabled={isReadOnly} /><label for='private' className="fsBig"><b>&nbsp;Private</b></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb10">
                            <div className='col-sm-6'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label style={{ paddingTop: 10, paddingLeft: 20 }} for='creditRankingId' className="fsBig"><b>Credit Ranking:</b></label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Dropdown
                                            placeholder='Select Credit Ranking'
                                            name='creditRankingId'
                                            fluid
                                            search
                                            selection
                                            clearable
                                            onChange={this.handleDropDownChange}
                                            options={creditRankingList}
                                            value={creditRankingId}
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label style={{ paddingTop: 10, paddingLeft: 20 }} for='statusId' className="fsBig"><b>Status:</b></label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Dropdown
                                            placeholder='Select Status'
                                            name='statusId'
                                            fluid
                                            search
                                            selection
                                            clearable
                                            onChange={this.handleDropDownChange}
                                            options={statusList}
                                            value={statusId}
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb10">
                            <div className='col-sm-6'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label style={{ paddingTop: 10, paddingLeft: 20 }} for='expectedExecutionDate' className="fsBig"><b>Expected Execution Date:</b></label>
                                    </div>
                                    <div className="col-sm-8" >
                                        <input type="text"
                                            placeholder="Expected Execution Date"
                                            name="expectedExecutionDate"
                                            autoComplete="off"
                                            value={expectedExecutionDate}
                                            className={'form-control inputLightP'}
                                            onChange={this.handleChangeTextBox}
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label style={{ paddingTop: 10, paddingLeft: 20 }} for='countryOfRiskId' className="fsBig"><b>Country Of Risk:</b></label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Dropdown
                                            placeholder='Select Country Of Risk'
                                            name='countryOfRiskId'
                                            fluid
                                            search
                                            selection
                                            clearable
                                            onChange={this.handleDropDownChange}
                                            options={countryList}
                                            value={countryOfRiskId}
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb10">
                            <div className='col-sm-6'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label style={{ paddingTop: 10, paddingLeft: 20 }} for='gicsSectorId' className="fsBig"><b>GICS Sector:</b></label>
                                    </div>
                                    <div className="col-sm-8">
                                        <Dropdown
                                            placeholder='Select GICS Sector'
                                            name='gicsSectorId'
                                            fluid
                                            search
                                            selection
                                            clearable
                                            onChange={this.handleDropDownChange}
                                            options={gicsSectorList}
                                            value={gicsSectorId}
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label style={{ paddingTop: 10, paddingLeft: 20 }} for='purchaseIssuePrice' className="fsBig"><b>Purchase/Issue Price:</b></label>
                                    </div>
                                    <div className="col-sm-8">
                                        <NumberFormat
                                            placeholder="Purchase Issue Price"
                                            name='purchaseIssuePrice'
                                            value={this.getFormatted(purchaseIssuePrice)}
                                            thousandSeparator={true}
                                            onValueChange={(values) => { this.handleChangeNew('purchaseIssuePrice', values.floatValue); }}
                                            className={'form-control inputLightP'}
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb10">
                            <div className='col-sm-6'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label style={{ paddingTop: 10, paddingLeft: 20 }} for='cashCoupon' className="fsBig"><b>Cash Coupon:</b></label>
                                    </div>
                                    <div className="col-sm-8">
                                        <NumberFormat
                                            placeholder="Cash Coupon"
                                            name='cashCoupon'
                                            value={this.getFormatted(cashCoupon)}
                                            thousandSeparator={true}
                                            onValueChange={(values) => { this.handleChangeNew('cashCoupon', values.floatValue); }}
                                            className={'form-control inputLightP'}
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label style={{ paddingTop: 10, paddingLeft: 20 }} for='pikCoupon' className="fsBig"><b>PIK Coupon:</b></label>
                                    </div>
                                    <div className="col-sm-8">
                                        <NumberFormat
                                            placeholder="PIK Coupon"
                                            name='pikCoupon'
                                            value={this.getFormatted(pikCoupon)}
                                            thousandSeparator={true}
                                            onValueChange={(values) => { this.handleChangeNew('pikCoupon', values.floatValue); }}
                                            className={'form-control inputLightP'}
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb10">
                            <div className='col-sm-6'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label style={{ paddingTop: 10, paddingLeft: 20 }} for='equityUpside' className="fsBig"><b>Equity Upside:</b></label>
                                    </div>
                                    <div className="col-sm-8" style={{ width: 470, paddingLeft: 10, paddingTop: 10 }} onChange={this.handleChangeCheckbox}>
                                        <input type="radio" id='equityUpside' value={true} name='equityUpside' checked={equityUpside === true} disabled={isReadOnly} /> <label for='equityUpside' className="fsBig"><b>Yes</b></label>&nbsp;&nbsp;&nbsp;
                                        <input type="radio" id='noEquityUpside' value={false} name='equityUpside' checked={equityUpside === false} disabled={isReadOnly} /><label for='noEquityUpside' className="fsBig"><b>&nbsp;No</b></label>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label style={{ paddingTop: 10, paddingLeft: 20 }} for='expectedTenor' className="fsBig"><b>Expected Tenor (Years):</b></label>
                                    </div>
                                    <div className="col-sm-8">
                                        <NumberFormat
                                            placeholder="Expected Tenor(Years)"
                                            name='expectedTenor'
                                            value={this.getFormatted(expectedTenor)}
                                            thousandSeparator={true}
                                            onValueChange={(values) => { this.handleChangeNew('expectedTenor', values.floatValue); }}
                                            className={'form-control inputLightP'}
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb10">
                            <div className='col-sm-6'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label style={{ paddingTop: 10, paddingLeft: 20 }} for='expectedTorSize' className="fsBig"><b>Expected Tor Size (in millions USD):</b></label>
                                    </div>
                                    <div className="col-sm-8">
                                        <NumberFormat
                                            placeholder="Expected Tor Size(in millions USD)"
                                            name='expectedTorSize'
                                            value={this.getFormatted(expectedTorSize)}
                                            thousandSeparator={true}
                                            suffix={'M'}
                                            prefix={'$'}
                                            onValueChange={(values) => { this.handleChangeNew('expectedTorSize', values.floatValue); }}
                                            className={'form-control inputLightP'}
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label style={{ paddingTop: 10, paddingLeft: 20 }} for='totalDealSize' className="fsBig"><b>Total Deal Size (in millions USD):</b></label>
                                    </div>
                                    <div className="col-sm-8">
                                        <NumberFormat
                                            placeholder="Total Deal Size(in millions USD)"
                                            name='totalDealSize'
                                            value={this.getFormatted(totalDealSize)}
                                            thousandSeparator={true}
                                            suffix={'M'}
                                            prefix={'$'}
                                            onValueChange={(values) => { this.handleChangeNew('totalDealSize', values.floatValue); }}
                                            className={'form-control inputLightP'}
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb10">
                            <div className='col-sm-2'>
                                <label style={{ paddingTop: 10, paddingLeft: 20 }} for='expectedIRR' className="fsBig"><b>Expected IRR (Unlevered):</b></label>
                            </div>
                            <div className="col-sm-6">
                                <input type="text"
                                    placeholder="Expected IRR (Unlevered)"
                                    name="expectedIRR"
                                    autoComplete="off"
                                    value={expectedIRR}
                                    className={'form-control inputLightP'}
                                    onChange={this.handleChangeTextBox}
                                    disabled={isReadOnly}
                                />
                            </div>
                        </div>
                        <div className="row mb10">
                            <div className="col-sm-2">
                                <label style={{ paddingTop: 10, paddingLeft: 20 }} for='comment' className="fsBig"><b>Comment:</b></label>
                            </div>
                            <div className="col-sm-8">
                                <textarea type="text" style={{ width: 1170 }}
                                    placeholder="Comment"
                                    name="comment"
                                    value={comment}
                                    rows="10"
                                    onChange={this.handleChangeTextBox}
                                    disabled={isReadOnly}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%', height: '100%' }}>
                    <div style={{ paddingBottom: 15, paddingTop: 10, height: '10px' }}>
                        <div style={{ float: "right", paddingDown: "15px", paddingRight: "15px", cursor: "pointer" }} onClick={this.saveDB}>
                            {
                                isReadOnly && !isIRUser ? <div></div> :
                                    <FontAwesomeIcon icon={faSave} style={{ cursor: "pointer" }} tooltip="true" title="Save" />
                            }
                        </div>
                    </div>
                </div>
                <Prompt when={this.state.isOpenGrid === false && JSON.stringify(existingDealPipeSummary) !== JSON.stringify(newDealPipeSummary)}
                    message="Do you want to discard changes?" />
                <ModalForm modalType={modalType}
                    modalVisible={modalVisible}
                    modalHeading={modalHeading}
                    gridData={gridReminderData}
                    defaultColDef={this.props.defaultColDef}
                    onClickAway={() => this.closeModal()}
                    onClickClose={() => this.closeModal()}
                    onClickYes={() => this.handleModalChange()}
                    generateReport={() => this.generateReport()}
                    handleChange={this.handleChange}
                    handleDropDownChange={this.handleDropDownChange} />
            </div>
        );
    }
}

export default DealPipelineSummary;