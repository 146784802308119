import React, { Component } from 'react';

import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faBackward } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from 'semantic-ui-react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import _ from 'underscore';
import Modal from 'react-awesome-modal';

import { reportGeneratorBALService } from '../../bal/reportGenerator.bal'
import { portfolioProjectionBalService } from '../../bal/Portfolio-Dashboard/portfolioProjection.bal'
import { userBALService } from '../../bal/user.bal';
import { torGridBALService } from '../../bal/torGridBALService.bal';
import { dealDashboardBALService } from '../../bal/Deal-Dashboard/dealDashboard.bal';
import PortfolioOverview from './portfolio_overview.js';
import HedgeOverview from './hedge_overview.js';
import FxOverview from './fx_overview.js';
import PositionView from './positionView';
import StressView from './stressView';
import PortfolioPerformanceGrid from './portfolioPerformanceGrid.js';
import PortfolioPerformanceOverview from './portfolioPerformanceOverview.js';
import PortfolioProjection from './portfolioProjection.js';
import RealEstateRisk from './realEstateRisk';
import CommodityRisk from './commodityRisk';
import CreditMetrics from './creditMetrics';
import CashMargin from './cashMargin'
import ToastMessage from "./../deals/components/toastMessage";
import SideDrawer from './components/sideDrawer.js';

import './portfolioDashboard.css';

class PortfolioDashboard extends Component {
  constructor(props) {
    super(props);

    this.mounted = false;
    this.timeout = 250;
    this.gridApi = null;

    this.state = {
      GridId: "positions1" ?? this.constructor.name + "_default",
      drawerOpen: false,
      GridTitle: 'Portfolio > Grid',
      display: "none",
      thetaChartData: [],
      tabindex: 4,
      chartMaturityData: [],
      chartVintageData: [],
      maturityLabel: [],
      vintageLabel: [],
      AsOfDate: null,
      Frequency: 'm',
      gridData: [],
      fxGridData: [],
      pnlGridData: [],
      projectionGridData: [],
      hedgeData: [],
      filterData: [],
      stressChartData: [],
      portfolioChartData: [],
      GroupBy: "portfolio",
      GroupByName: "Public/Private",
      fund: "All",
      MessageBoxBackground: "Red",
      isOpenLoad: false,
      dateList: [],
      modalVisible: false,
      modalType: 'close',
      modalHeading: '',
      modalHandler: '',
      projectionDeal: null,
      projectionFund: null,
      isCollapsed: true,
      fundList: [
        { key: 0, text: "TACF", value: "TOR" },
        { key: 1, text: "TACO", value: "TACO" },
        { key: 2, text: "TACO II", value: "TACO II" },
        { key: 3, text: "TACO III", value: "TACO III" },
        { key: 4, text: "TIFO", value: "TIFO" },
        { key: 5, text: "TALC", value: "TALC" },
        { key: 6, text: "ALL", value: "All" }
      ],
      groupByList: [
        { key: 0, text: "Public/Private", value: "portfolio" },
        { key: 1, text: "Country", value: "country" },
        { key: 2, text: "Market Risk Sensitivity", value: "market_risk" },
        { key: 3, text: "GICS Sector", value: "gics_sector" },
        { key: 4, text: "BICS Sector", value: "bics_sector" },
        { key: 5, text: "TACF Sub-strategy", value: "tacf_substrategy" },
        { key: 6, text: "TACOF Sub-strategy", value: "tacof_substrategy" },
        { key: 7, text: "Product Type", value: "product type" },
        { key: 8, text: "Asset Type", value: "asset_type" },
        { key: 9, text: "Ranking", value: "credit_ranking" },
        { key: 10, text: "Capital Type", value: "capital type" },
      ]
    }

    this.handleCloseErrorBox = this.handleCloseErrorBox.bind(this);
    this.refresh = this.refresh.bind(this);
    this.handleChangeAsOfDate = this.handleChangeAsOfDate.bind(this);
    this.handleDropDownChangeFund = this.handleDropDownChangeFund.bind(this);
    this.handleDropDownChangeGroupBy = this.handleDropDownChangeGroupBy.bind(this);
    this.filterData = this.filterData.bind(this);
    this.filterDate = this.filterDate.bind(this);
    this.toggleTabIndex = this.toggleTabIndex.bind(this);
    this.drawerToggleClickHandler = this.drawerToggleClickHandler.bind(this);
    this.handleGridTitle = this.handleGridTitle.bind(this);
    this.closeErrorMessage = this.closeErrorMessage.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleModalChange = this.handleModalChange.bind(this);
    this.closeMessage = this.closeMessage.bind(this);
    this.emailReport = this.emailReport.bind(this);
    this.handleRowSelected = this.handleRowSelected.bind(this);
    this.setAgGridAPI = this.setAgGridAPI.bind(this);
    this.approve = this.approve.bind(this);
    this.reject = this.reject.bind(this);
    this.togglePanelCollapse = this.togglePanelCollapse.bind(this);
  }

  componentDidMount = async () => {
    this.mounted = true;
    await torGridBALService.getRowData('hedgeReport', { query: 'Exec [sp_get_quarterly_dates] 0' }).then(data => {
      this.setState({ dateList: data });
      this.filterDate(data, data[0]['report date'], new Date(moment(data[0]['report date'], "YYYYMMDD").format("YYYY-MM-DD")));
    });

    this.refresh();
  }


  render() {
    return this.getTemplate();
  }

  handleChangeAsOfDate = (date) => {
    const { dateList } = this.state;
    if (date) {
      const frequencyList = dateList.filter(function (item) {
        return item['report date'] === parseInt(moment(date).format("YYYYMMDD"))
      });

      if (frequencyList?.length > 0 && frequencyList[0]) {
        this.setState({
          AsOfDate: moment(date).format("YYYY-MM-DD")
        }, async () => {
          await this.filterDate(this.state.dateList, parseInt(moment(date).format("YYYYMMDD")), moment(date).format("YYYY-MM-DD"));
          this.refresh();
        });
      } else {
        this.setState({
          AsOfDate: moment(date).format("YYYY-MM-DD")
        });
      }

    } else {
      this.setState({
        AsOfDate: null
      })
    }

  };

  handleDropDownChangeGroupBy = (event, data) => {
    const GroupBy = data.value
    const GroupByName = _.filter(this.state.groupByList, function (group) {
      return group.value === GroupBy
    });

    this.setState({
      GroupBy: GroupBy,
      GroupByName: GroupByName[0].text
    });
  };

  handleDropDownChangeFund = (event, data) => {
    const Fund = data.value
    this.setState({
      fund: Fund
    });

    if (this.state.tabindex === 6 || this.state.tabindex === 7 || this.state.tabindex === 3 || this.state.tabindex === 8) {
      if (this.state.AsOfDate !== null) {
        this.refresh(event, Fund);
      }
    } else {
      this.filterData('fund', Fund);
    }
  };

  handleCloseErrorBox = () => {
    this.setState({ display: "none" });
  }

  filterData(colName, value) {
    var data = this.state.gridData;

    if (value !== 'All') {
      var arr = data.filter(function (item) {
        return item[colName] === value
      });

      this.setState({
        filterData: arr
      });
    } else {
      this.setState({
        filterData: this.state.gridData
      });
    }
  }

  filterDate(data, date, formatDate) {
    const frequencyList = data.filter(function (item) {
      return item['report date'] === date
    });

    if (frequencyList?.length > 0 && frequencyList[0] && frequencyList[0]['for frequency'])
      this.setState({ AsOfDate: moment(formatDate).format("YYYY-MM-DD"), Frequency: frequencyList[0]['for frequency'] });
    else
      this.setState({ AsOfDate: moment(formatDate).format("YYYY-MM-DD"), Frequency: null });
  }

  emailReport() {
    reportGeneratorBALService.emailReport({ report_name: "FX", params: `Report_Date = ${moment(this.state.AsOfDate).format("YYYYMMDD")}` }).then(data => {
      this.setState({
        modalVisible: true,
        modalType: 'close',
        modalHandler: 'save',
        modalHeading: 'Report Generation request has been sent'
      });
    }, error => {
      this.setState({
        showMessage: true,
        errorList: [error],
        messageHeader: "Error while loading the data :",
        modalVisible: true,
        modalType: 'close',
        modalHandler: 'save',
        modalHeading: 'Error while loading the data'
      });
      console.log(error);
    })
  }

  getPositionsData = (fund) => {
    dealDashboardBALService.getPortfolioChartData({Frequency: this.state.Frequency, Year: moment(this.state.AsOfDate).format("YYYY")}).then(data => {
      this.setState({ portfolioChartData: data });
    }, error => {
      this.setState({
        showMessage: true,
        errorList: [error],
        messageHeader: "Error while loading the data :",
        modalVisible: true,
        modalType: 'close',
        modalHandler: 'save',
        modalHeading: 'Error while loading the data'
      });
      console.log(error);
    });

    torGridBALService.getRowData('hedgeReport', { query: `Exec [sp_get_hedge_summary] '${moment(this.state.AsOfDate).format("YYYY-MM-DD")}'` }).then(data => {
      this.setState({ hedgeData: data });
    }, error => {
      this.setState({
        showMessage: true,
        errorList: [error],
        messageHeader: "Error while loading the data :",
        modalVisible: true,
        modalType: 'close',
        modalHandler: 'save',
        modalHeading: 'Error while loading the data'
      });
      console.log(error);
    });

    return true;
  }

  refresh = async (e, fund = null) => {
    fund = fund ?? this.state.fund;
    const user = userBALService.getUserInfo();

    if (this.state.AsOfDate === null) {
      this.setState({
        modalVisible: true,
        modalType: 'close',
        modalHandler: 'save',
        modalHeading: 'Please select a date before refresh !'
      });
    } else if (this.state.tabindex === 1 || this.state.tabindex === 4 || this.state.tabindex === 5) {
      this.setState({ isOpenLoad: true });

      await torGridBALService.getDashboardData(this.state.GridId, { AsOfDate: this.state.AsOfDate, Frequency: this.state.Frequency }).then(data => {
        if (user?.userRoles?.includes("PM")) {
          this.setState({ gridData: data }, () => {
            this.filterData('fund', fund);
            this.setState({ isOpenLoad: false });
          });
        }
        else if (user?.userRoles?.includes("Analyst")) {
          const name = user?.user?.name;
          data = data.filter(function (item) {
            return item['Analyst 1'] === name || item['Analyst 2'] === name || item['Analyst 3'] === name
          });
          this.setState({ gridData: data }, () => {
            this.filterData('fund', fund);
            this.setState({ isOpenLoad: false });
          });
        } else {
          this.setState({ gridData: data }, () => {
            this.filterData('fund', fund);
            this.setState({ isOpenLoad: false });
          });
        }
      }, error => {
        this.setState({
          showMessage: true,
          errorList: [error],
          messageHeader: "Error while loading the data :",
          modalVisible: true,
          modalType: 'close',
          modalHandler: 'save',
          modalHeading: 'Error while loading the data'
        });
        console.log(error);
      });

      this.getPositionsData(fund);

    } else if (this.state.tabindex === 6 || this.state.tabindex === 7) {
      this.setState({ isOpenLoad: true });
      var AsOfDate = new Date(this.state.AsOfDate);

      await torGridBALService.getRowData('hedgeReport', { query: `Exec [sp_get_pnl_fund] '${fund}', 'm', '${AsOfDate.toISOString().split('T')[0]}', '${user?.user?.username}'` }).then(data => {
        this.setState({ pnlGridData: data });
        this.setState({ isOpenLoad: false });
      }, error => {
        this.setState({
          showMessage: true,
          errorList: [error],
          messageHeader: "Error while loading the data :",
          modalVisible: true,
          modalType: 'close',
          modalHandler: 'save',
          modalHeading: 'Error while loading the data'
        });
        console.log(error);
      });
    } else if (this.state.tabindex === 3) {
      this.setState({ isOpenLoad: true });
      await torGridBALService.getRowData('hedgeReport', { query: `Exec [sp_fx_exposure] '${fund}', 'd', '${moment(this.state.AsOfDate).format("YYYYMMDD")}', 1` }).then(data => {
        if (user?.userRoles?.includes("Analyst")) {
          const name = user?.user?.name;
          data = data.filter(function (item) {
            return item['Analyst 1'] === name || item['Analyst 2'] === name || item['Analyst 3'] === name
          });
        }
        this.setState({ fxGridData: data });
        this.setState({ isOpenLoad: false });
      }, error => {
        this.setState({
          showMessage: true,
          errorList: [error],
          messageHeader: "Error while loading the data :",
          modalVisible: true,
          modalType: 'close',
          modalHandler: 'save',
          modalHeading: 'Error while loading the data'
        });
        console.log(error);
      });
    } else if (this.state.tabindex === 8) {
      this.setState({ isOpenLoad: true });

      await torGridBALService.getRowData('hedgeReport', { query: `exec [sp_portfolio_projection] '${fund}', '${user?.user?.username}'` }).then(data => {
        this.setState({ projectionGridData: data });
        this.setState({ isOpenLoad: false });
      }, error => {
        this.setState({
          showMessage: true,
          errorList: [error],
          messageHeader: "Error while loading the data :",
          modalVisible: true,
          modalType: 'close',
          modalHandler: 'save',
          modalHeading: 'Error while loading the data'
        });
        console.log(error);
      });
    }
  }

  drawerToggleClickHandler = () => {
    this.setState({
      drawerOpen: !this.state.drawerOpen
    })
  }

  handleGridTitle = (val) => {
    var gridTitle = ''
    if (val === 1) {
      gridTitle = 'Portfolio > Overview'
    } else if (val === 4) {
      gridTitle = 'Portfolio > Grid'
    } else if (val === 5) {
      gridTitle = 'Portfolio > Stress'
    } else if (val === 2) {
      gridTitle = 'Portfolio > Hedges'
    } else if (val === 3) {
      gridTitle = 'Portfolio > FX'
    } else if (val === 6) {
      gridTitle = 'Performance > Overview'
    } else if (val === 7) {
      gridTitle = 'Performance > Grid'
    } else if (val === 8) {
      gridTitle = 'Performance > Projection'
    } else if (val === 9) {
      gridTitle = 'Exposure > Credit Metrics'
    } else if (val === 10) {
      gridTitle = 'Exposure > Commodity Risk'
    } else if (val === 11) {
      gridTitle = 'Exposure > Real Estate Risk'
    } else if (val === 12) {
      gridTitle = 'Performance > Cash/ Margin'
    } 

    this.setState({
      GridTitle: gridTitle
    })
  }

  toggleTabIndex = async (val) => {
    const { tabindex } = this.state;
    if (this.state.tabindex !== val) {
      this.setState({
        tabindex: val,
        drawerOpen: false
      }, async () => {
        this.handleGridTitle(val);

        var fundList = [];
        if ((val === 1 || val === 4 || val === 5) && tabindex !== 1 && tabindex !== 4 && tabindex !== 5) {
          fundList = [
            { key: 0, text: "TACF", value: "TOR" },
            { key: 1, text: "TACO", value: "TACO" },
            { key: 2, text: "TACO II", value: "TACO II" },
            { key: 3, text: "TACO III", value: "TACO III" },
            { key: 4, text: "TIFO", value: "TIFO" },
            { key: 5, text: "TALC", value: "TALC" },
            { key: 6, text: "ALL", value: "All" }
          ]

          this.setState({
            fundList: fundList,
            AsOfDate: null,
            fund: 'All',
            Frequency: 'm',
            gridData: [],
            filterData: []
          });

          await torGridBALService.getRowData('hedgeReport', { query: 'Exec [sp_get_quarterly_dates] 0' }).then(data => {
            this.setState({ dateList: data });
            this.filterDate(data, data[0]['report date'], new Date(moment(data[0]['report date'], "YYYYMMDD").format("YYYY-MM-DD")));
          });

          this.refresh();

        } else if (val === 2 || val === 12) {
          fundList = [
            { key: 0, text: "TACF", value: "TOR" },
            { key: 3, text: "TIFO", value: "TIFO" },
            { key: 5, text: "TALC", value: "TALC" }
          ]

          this.setState({
            fundList: fundList,
            fund: 'TOR',
            AsOfDate: null,
            Frequency: 'd',
            gridData: [],
            filterData: []
          });

          await torGridBALService.getRowData('hedgeReport', { query: 'Exec [sp_get_quarterly_dates] 3' }).then(data => {
            this.setState({ dateList: data });
            this.filterDate(data, data[0]['report date'], new Date(moment(data[0]['report date'], "YYYYMMDD").format("YYYY-MM-DD")));
          });

        } else if (val === 3) {
          fundList = [
            { key: 0, text: "TACF", value: "TOR" },
            { key: 1, text: "TACO", value: "TACO" },
            { key: 2, text: "TACO II", value: "TACO II" },
            { key: 3, text: "TACO III", value: "TACO III" },
            { key: 4, text: "TIFO", value: "TIFO" },
            { key: 5, text: "TALC", value: "TALC" }
          ]

          this.setState({
            fundList: fundList,
            fund: 'TOR',
            AsOfDate: null,
            Frequency: 'd',
            gridData: [],
            filterData: []
          });

          await torGridBALService.getRowData('hedgeReport', { query: 'Exec [sp_get_quarterly_dates] 2' }).then(data => {
            this.setState({ dateList: data });
            this.filterDate(data, data[0]['report date'], new Date(moment(data[0]['report date'], "YYYYMMDD").format("YYYY-MM-DD")));
          });

          this.refresh();
        } else if ((val === 6 || val === 7) && tabindex !== 6 && tabindex !== 7) {
          fundList = [
            { key: 0, text: "TACF", value: "TOR" },
            { key: 1, text: "TACO", value: "TACO" },
            { key: 2, text: "TACO II", value: "TACO II" },
            { key: 3, text: "TACO III", value: "TACO III" },
            { key: 4, text: "TIFO", value: "TIFO" },
            { key: 5, text: "TALC", value: "TALC" },
          ]

          this.setState({
            fundList: fundList,
            fund: 'TOR',
            AsOfDate: null,
            Frequency: 'm',
            gridData: [],
            filterData: []
          });

          await torGridBALService.getRowData('hedgeReport', { query: 'Exec [sp_get_quarterly_dates] 1' }).then(data => {
            this.setState({ dateList: data });
            this.filterDate(data, data[0]['report date'], new Date(moment(data[0]['report date'], "YYYYMMDD").format("YYYY-MM-DD")));
          });

          this.refresh();
        } else if (val === 8) {
          fundList = [
            { key: 0, text: "TACF", value: "TOR" },
            { key: 1, text: "TACO", value: "TACO" },
            { key: 2, text: "TACO II", value: "TACO II" },
            { key: 3, text: "TACO III", value: "TACO III" },
            { key: 4, text: "TIFO", value: "TIFO" },
            { key: 5, text: "TALC", value: "TALC" }
          ]

          this.setState({
            fundList: fundList,
            fund: 'All'
          });

          this.refresh();
        }
      });


    }
  }

  closeModal() {
    if (this.state.modalHandler === 'refresh') {
      window.location.reload();
    }
    this.setState({
      modalVisible: false
    });
  }

  handleModalChange() {
    if (this.state.modalHandler === 'refresh') {
      this.setState({ isOpenGrid: true, modalVisible: false });
    } else if (this.state.modalHandler === 'save') {
      this.saveDB();
      this.setState({ isOpenGrid: true, modalVisible: false });
    } else {
      this.setState({ isOpenGrid: true, modalVisible: false });
    }
  }

  closeErrorMessage() {
    this.setState({ showMessage: false, errorList: [] });
  }

  closeMessage() {
    this.setState({ showMessage: false, messageHeader: "" });
  }

  handleRowSelected() {
    const selectedRows = this.gridApi?.getSelectedNodes();
    console.log(selectedRows);
    
    if (selectedRows?.length > 0) {
      const row = selectedRows.map(deal => { 
        return deal.data ? { Deal: deal.data['Deal'], Fund: deal.data['Fund'] } : { Deal: null, Fund: null } });
      console.log(row);
      this.setState({
        projectionDeal: row[row.length - 1].Deal,
        projectionFund: row[row.length - 1].Fund
      })
    } else {
      this.setState({
        projectionDeal: null,
        projectionFund: null
      })
    }
  }

  setAgGridAPI(api) {
    this.gridApi = api;
  }

  approvalRejection = (action) => {
    var selectRows = this.gridApi?.getSelectedNodes();

    const user = userBALService.getUserInfo();
    selectRows = selectRows.filter(deal => {
      return deal.data['Analyst 1'] === user?.user?.name
    });

    var selectedRows = selectRows.filter(deal => {
      return deal.data['Approval Status'] !== 'Approved'
    });

    if (selectedRows?.length > 0) {
      const proposals = selectedRows.map(deal => { return { id: deal.data['projectionId'], action: action } });

      var promises = portfolioProjectionBalService.approveGrid(proposals);
      Promise.all(promises).then(data => {
        //var d = JSON.parse(data);
        var errorList = data.filter(element => element.error).map(element => { return element.error });
        if (errorList && errorList.length > 0)
          this.setState({
            showMessage: true,
            errorList: errorList,
            messageHeader: `Error while ${action.toLowerCase().startsWith('approve') ? 'approving' : action.toLowerCase().startsWith('reject') ? 'rejecting' : action} the request :`,
            modalVisible: true,
            modalType: 'close',
            modalHandler: 'save',
            modalHeading: `${action} failed, please contact support!`
          });
        else {
          this.setState({
            modalVisible: true,
            modalType: 'close',
            modalHandler: 'save',
            modalHeading: `${action} sucessfully!`
          });
        }
        this.refresh();
      }
      );
    } else if(selectRows?.length > 0) {
      this.setState({
        modalVisible: true,
        modalType: 'close',
        modalHandler: '',
        modalHeading: `Proposal(s) have already been ${action?.toLowerCase().startsWith('approve') ? 'Approved' : action?.toLowerCase().startsWith('reject') ? 'Rejected' : action}`
      })
    } else {
      this.setState({
        modalVisible: true,
        modalType: 'close',
        modalHandler: '',
        modalHeading: `First select proposal(s) to ${action?.toLowerCase().startsWith('approve') ? 'Approve' : action?.toLowerCase().startsWith('reject') ? 'Reject' : action}`
      })
    }
  }

  approve() {
    this.approvalRejection('Approved');
  }

  reject() {
    this.approvalRejection('Rejected');
  }

  togglePanelCollapse() {
    this.setState({
      isCollapsed: !this.state.isCollapsed
    })
  }

  getTemplate() {
    const { MessageBoxBackground, GridTitle, AsOfDate, groupByList, GroupBy, fund, fundList, dateList, error, display, drawerOpen, tabindex, isOpenLoad, modalHeading, projectionDeal, projectionFund } = this.state;
    const includeDate = dateList.map(item => {
      return new Date(moment(item['report date'], "YYYYMMDD").format("YYYY-MM-DD"))
    });
    const user = userBALService.getUserInfo();
    return (
      <div style={{ gridTemplateRows: 'auto auto auto 1fr auto', display: 'grid', height: '100%', width: '100%', position: 'relative', resizable: true }}>
        {
          this.state.showMessage ?
            <ToastMessage
              header={this.state.messageHeader}
              errorList={this.state.errorList}
              closeMessage={this.closeErrorMessage}
            /> : <div></div>
        }
        <SideDrawer show={drawerOpen} toggleTabIndex={this.toggleTabIndex} tabindex={tabindex} toggleDrawer={this.drawerToggleClickHandler} userRoles={this.props.userRoles} />
        <div className="content-container" style={{ height: "100%", width: '100%', border: "solid 1px #babfc7", padding: "0px 0px 0px 0px" }}>
          <div style={{ float: "left", padding: "14px 6px 10px 16px", cursor: "pointer" }} onClick={this.drawerToggleClickHandler} >
            <FontAwesomeIcon icon={faBackward} />
          </div>
          <div style={{ display: (GridTitle && GridTitle !== '') ? "inline" : "none", float: "left", height: "50px", borderRight: "solid 1px #babfc7", position: "relative", padding: "14px 10px 10px 10px", cursor: "pointer" }} onClick={this.drawerToggleClickHandler}>
            <b>{GridTitle}</b>
          </div>
          <Collapse isOpen={true} style={{ float: "left", height: "auto" }}>
            {
              tabindex === 2 || tabindex === 3 || tabindex === 5 || tabindex === 8 || tabindex === 9 || tabindex === 10 || tabindex === 11 || tabindex === 12
                ?
                null
                :
                <div style={{ float: "left", height: "50px", padding: "14px 10px 10px 10px", cursor: "pointer" }}>
                  Pivoting:
                </div>
            }
            {
              tabindex === 2 || tabindex === 3 || tabindex === 5 || tabindex === 8 || tabindex === 9 || tabindex === 10 || tabindex === 11 || tabindex === 12
                ?
                null
                :
                <div style={{ float: "left", height: "50px", padding: "5px 0px 0px 10px", cursor: "pointer", minWidth: "200px" }}>
                  <Dropdown
                    placeholder='Select Group By'
                    name="groupBy"
                    fluid
                    search
                    selection
                    onChange={this.handleDropDownChangeGroupBy}
                    options={groupByList}
                    value={GroupBy}
                    className={'form-control'}
                  />
                </div>
            }
            {
              tabindex === 9 || tabindex === 12
                ?
                null
                :
                <div style={{ float: "left", height: "50px", padding: "14px 10px 10px 10px", cursor: "pointer" }}>
                  Fund Filter:
                </div>
            }
            {
              tabindex === 9 || tabindex === 12
                ?
                null
                :
                <div style={{ float: "left", height: "50px", padding: "5px 0px 0px 10px", cursor: "pointer", minWidth: "200px" }}>
                  <Dropdown
                    placeholder='Select Fund'
                    name="fund"
                    fluid
                    search
                    selection
                    onChange={this.handleDropDownChangeFund}
                    options={fundList}
                    value={fund}
                    className={'form-control'}
                  />
                </div>
            }
          </Collapse>

          <div style={{ float: "right", display: display, background: { MessageBoxBackground } }} className="alert alert-danger alert-dismissible fade show" role="alert">
            <span>{error}</span>
            <span className="close" data-dismiss="alert" aria-label="Close" style={{ cursor: "pointer" }} onClick={this.handleCloseErrorBox}>
              <span aria-hidden="true">&times;</span>
            </span>
          </div>

          <Collapse isOpen={true} style={{ float: "right", height: "auto" }}>
            <div style={{ float: "right", padding: "14px 10px 10px 10px", cursor: "pointer" }}>
              <FontAwesomeIcon onClick={this.refresh} icon={faSync} />
            </div>
            {
              tabindex === 8 || tabindex === 9
                ?
                null
                :
                <div style={{ float: "left", height: "50px", padding: "14px 10px 10px 10px", cursor: "pointer" }}>
                  As Of Date:
                </div>
            }
            <div style={{ float: "right", height: "50px", padding: "14px 10px 10px 10px", cursor: "pointer" }}>
              {
                tabindex === 8 || tabindex === 9
                  ?
                  null
                  :
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    name="AsOfDate"
                    selected={AsOfDate ? new Date(AsOfDate) : null}
                    includeDates={includeDate}
                    onChange={this.handleChangeAsOfDate}
                    autoComplete="off"
                    showYearDropdown
                    showMonthDropdown
                  />
              }
            </div>
          </Collapse>
        </div>
        {
          isOpenLoad === false
            ?
            <div className="scrollForm">
              {
                this.state.tabindex === 1 ?
                  <PortfolioOverview
                    GroupBy={this.state.GroupBy}
                    GroupByName={this.state.GroupByName}
                    fund={fund}
                    Frequency={this.state.Frequency}
                    gridData={this.state.filterData}
                    chartData={this.state.portfolioChartData}
                    AsOfDate={AsOfDate}
                  />
                  :
                  this.state.tabindex === 4 ?
                    <PositionView
                      GroupBy={this.state.GroupBy}
                      gridData={this.state.filterData} />
                    :
                    this.state.tabindex === 5 ?
                      <StressView
                        GroupBy={this.state.GroupBy}
                        hedgeData={this.state.hedgeData}
                        fund={fund}
                        Frequency={this.state.Frequency}
                        gridData={this.state.filterData}
                        chartData={this.state.portfolioChartData}
                        AsOfDate={AsOfDate}
                      />
                      :
                      this.state.tabindex === 2
                        ?
                        <HedgeOverview
                          Fund={fund === 'TOR' ? 'TACF' : fund}
                          AsOfDate={AsOfDate}
                        />
                        :
                        this.state.tabindex === 3
                          ?
                          <FxOverview
                            Fund={fund === 'All' ? 'TOR' : fund}
                            gridData={this.state.fxGridData}
                            AsOfDate={AsOfDate}
                            emailReport={this.emailReport}
                          />
                          :
                          this.state.tabindex === 6 ?
                            <PortfolioPerformanceOverview
                              GroupBy={
                                this.state.GroupBy === "country"
                                  ? "Country"
                                  : this.state.GroupBy
                              }
                              Fund={fund}
                              AsOfDate={AsOfDate}
                              gridData={this.state.pnlGridData}
                              user={user}
                            /> :
                            this.state.tabindex === 7 ?
                              <PortfolioPerformanceGrid
                                GroupBy={this.state.GroupBy}
                                gridData={this.state.pnlGridData}
                                user={user}
                              /> :
                              this.state.tabindex === 8
                                ?
                                <PortfolioProjection
                                  Fund={fund}
                                  projectionDeal={projectionDeal}
                                  projectionFund={projectionFund}
                                  gridData={this.state.projectionGridData}
                                  username={user?.user?.username}
                                  setAgGridAPI={this.setAgGridAPI}
                                  rowSelected={this.handleRowSelected}
                                  approve={this.approve}
                                  isCollapsed = {this.state.isCollapsed}
                                  togglePanelCollapse = {this.togglePanelCollapse}
                                />
                                :
                                this.state.tabindex === 9
                                  ?
                                  <CreditMetrics username={user?.user?.username} />
                                  :
                                  this.state.tabindex === 10
                                    ?
                                    <CommodityRisk
                                      Fund={fund}
                                      AsOfDate={moment(AsOfDate).format('YYYYMMDD')}
                                      Frequency={this.state.Frequency}
                                      username={user?.user?.username}
                                    />
                                    :
                                    this.state.tabindex === 11
                                      ?
                                      <RealEstateRisk
                                        Fund={fund}
                                        AsOfDate={moment(AsOfDate).format('YYYYMMDD')}
                                        Frequency={this.state.Frequency}
                                        username={user?.user?.username}
                                      />
                                      :
                                      this.state.tabindex === 12
                                        ?
                                        <CashMargin
                                          Fund={fund}
                                          AsOfDate={moment(AsOfDate).format('YYYYMMDD')}
                                          Frequency={this.state.Frequency}
                                          username={user?.user?.username}
                                        />
                                        :
                                        null
              }
            </div>
            :
            <div style={{ padding: "5px 0px 8px 0px", position: "relative", borderTop: "solid 1px white", height: "100%" }}>
              <img src={require('../../images/Loading.gif')} alt="Loading..." />
            </div>
        }
        <div>
          <section>
            {
              this.state.modalType === 'close'
                ?
                <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                  <div className="p20 centerElem" style={{ width: "350px", height: "220px" }}>
                    <h1>{modalHeading}</h1>
                    <button className="graybtnDes mt100" onClick={() => this.closeModal()}>Close</button>
                  </div>
                </Modal>
                :
                this.state.modalType === 'saveChanges'
                  ?
                  <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                    <div className="p20 centerElem" style={{ width: "420px", height: "270px" }}>
                      <h1>{modalHeading}</h1>
                      <button className="btnDes mt100" onClick={() => this.handleModalChange()}>Yes</button>
                      <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                    </div>
                  </Modal>
                  :
                  <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                    <div className="p20 centerElem" style={{ width: "350px", height: "220px" }}>
                      <h1>{modalHeading}</h1>
                      <button className="btnDes mt100" onClick={() => this.handleModalChange()}>Yes</button>
                      <button className="graybtnDes mt100 ml20" onClick={() => this.closeModal()}>No</button>
                      <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                    </div>
                  </Modal>
            }
          </section>
        </div>
      </div>
    );
  }
}
export default PortfolioDashboard;