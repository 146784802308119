import './../../App.css'
import ModalForm from '../common/modalForm'
import { userBALService } from '../../bal/user.bal'
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import TorGrid from '../torgrid/torgrid';
import ToastMessage from "../deals/components/toastMessage";
import { Prompt } from "react-router-dom";
import { taskInstanceBALService } from '../../bal/taskInstance.bal';
import history from '../../history/history';
import { Dropdown } from 'semantic-ui-react'; 


//https://fontawesome.com/icons?d=gallery
//https://stackoverflow.com/questions/56559772/where-do-i-find-the-object-names-of-icons-in-the-fontawesome-free-packages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

class TaskInstanceDashboard extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;

        const today = new Date();
        const yesterday = new Date(today);

        yesterday.setDate(yesterday.getDate() - 14);

        this.state = {
            ...userBALService.getUserDetails(),
            rowData: [],
            id: null,
            isOpenGrid: true,
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "",
            showMessage: false,
            gridData: [],
            gridDataFiltered: [],
            editType: 'fullRow',
            existingTaskInstance: [],
            newTaskInstance: [],
            dealList: [],
            editorState: null,  
            selectedRows: null,
            selectedOption : "DueOrOverdue",
            duration: 1,
            durationUpcoming: 1,
            durationList: [
                {key:1, value: 1,  text: "1 month"},
                {key:2, value: 2,  text: "2 months"},
                {key:3, value: 3,  text: "3 months"},
                {key:6, value: 6,  text: "6 months"},
                {key:12, value: 12, text: "1 year"},
                {key:24, value: 24, text: "2 years"},
                {key:36, value: 36, text: "3 years"}
            ]
        };
        this.render = this.render.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.setAgGridAPI = this.setAgGridAPI.bind(this);
        this.getPage = this.getPage.bind(this);
        this.addNew = this.addNew.bind(this);
        this.refresh = this.refresh.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleRowSelected = this.handleRowSelected.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.refresh();
    }

    handleDropDownChange(e, { name, value }) {

        this.handleFilter(null, value);

    }

    handleOptionChange (e) {
        this.handleFilter(e.target.value, null);
    }

    handleFilter (selectedOptionNew, durationNew=null) {
        const { duration, durationUpcoming, gridData, selectedOption } = this.state;
        selectedOptionNew = selectedOptionNew ?? selectedOption;
        durationNew = durationNew ?? duration;
        const durationNewUpcoming = durationNew ?? durationUpcoming;
        let currentDate = new Date(); 
        currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        let gridDataFiltered = []

        console.log(gridData[0].softDeadline === null);
        console.log(gridData[0]);

        if(selectedOptionNew === "Due") {
            gridDataFiltered = gridData.filter(p=> (currentDate >= p.forDate && currentDate <= p.hardDeadline && p.status !== 'Completed') );
        } else if(selectedOptionNew === "DueOrUpcoming") {
            const nextDate = new Date(currentDate.setMonth(currentDate.getMonth() + durationNew));
            gridDataFiltered = gridData.filter(p=> ((( gridData[0].softDeadline === null && p.hardDeadline <= nextDate ) || ( gridData[0].softDeadline !== null && p.softDeadline <= nextDate )) && p.status !== 'Completed') );
        } else if(selectedOptionNew === "Upcoming") {
            const nextDate = new Date(currentDate.setMonth(currentDate.getMonth() + durationNewUpcoming));
            gridDataFiltered = gridData.filter(p=> ( p.dueOverdue === "Upcoming" && (( gridData[0].softDeadline === null && p.hardDeadline <= nextDate ) || ( gridData[0].softDeadline !== null && p.softDeadline <= nextDate )) && p.status !== 'Completed'));
        } else if(selectedOptionNew === "Overdue") {
            gridDataFiltered = gridData.filter(p=> (currentDate > p.hardDeadline && p.status !== 'Completed'));
        } else if(selectedOptionNew === "DueOrOverdue") {
            gridDataFiltered = gridData.filter(p=> (currentDate > p.forDate && p.status !== 'Completed'));
        } else if(selectedOptionNew === "Completed") {
            gridDataFiltered = gridData.filter(p=> p.status === 'Completed' );
        }
        else if(selectedOptionNew === "All") {
            gridDataFiltered = gridData; 
        }

        this.setState({
            ...(selectedOptionNew === "DueOrUpcoming" || selectedOptionNew === "Upcoming" ) && {[selectedOptionNew === "DueOrUpcoming" ? 'duration' : 'durationUpcoming']: selectedOptionNew === "DueOrUpcoming" ? durationNew : durationNewUpcoming},
            selectedOption: selectedOptionNew,
            gridDataFiltered: gridDataFiltered
        });
        
    }

    render() {
        if (this.state.redirectTo)
            return <Redirect to={this.state.redirectTo} />;
        return this.getPage();
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    closeMessage() {
        this.setState({ showMessage: false, errorList: [], messageHeader: "" });
    }

    setAgGridAPI(api) {
        this.gridApi = api;
        this.gridApprovalApi = api;
    }

    addNew = async () => {
        history.push('/taskInstance');
        window.location.pathname = '/taskInstance';
    }

    refresh() {
        taskInstanceBALService.getRowData().then(data => {
            let showGrid = false;
            if(data && data.length > 0) {
                data = data.map(row => { return {
                            ...row, 
                            forDate: new Date(row.forDate),
                            hardDeadline: new Date(row.hardDeadline),
                            softDeadline: row.softDeadline ? new Date(row.softDeadline) : row.softDeadline,
                            existingValue: row?.value
                    }
                });
                showGrid = true;
            }
            let currentDate = new Date(); 
            currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.setState({
                gridData: data,
                gridDataFiltered: data.filter(p=> (currentDate > p.forDate && p.completionDate === null)),
                showGrid: showGrid
            });
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : `Error while retrieving Task Instance...`,
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Error while retrieving Task Instance!'
            }); 
            console.log(error);
        });
    }

    handleRowSelected(e) { 
        const { data } = e.target;
        console.log(data);
        this.setState({ redirectTo:{pathname : '/taskInstance', state : {data : data } } });
    }

    getPage() {
        const { modalType, showGrid, modalVisible, selectedOption, duration, durationUpcoming, durationList, modalHeading, gridDataFiltered, existingTaskInstance, newTaskInstance } = this.state;
        return (
            <div style={{ gridTemplateRows: 'auto auto 1fr', display: 'grid', height: '100%', width: '100%' }} >
                {
                    this.state.showMessage ?
                        <ToastMessage
                            header={this.state.messageHeader}
                            errorList={this.state.errorList}
                            closeMessage={this.closeMessage}
                        /> : <div></div>
                } 
                <div>
                    <div style={{ float: "Left", padding: "5px 15px 0px 15px", cursor: "pointer" }} ><h4><u>Task Instance</u></h4></div>
                    <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={this.addNew}><FontAwesomeIcon icon={faPlusCircle} /></div>
                    <div style={{ float: "right", paddingRight: "15px", cursor: "pointer" }} onClick={this.refresh}><FontAwesomeIcon icon={faSync} /></div>
                    <div>
                        <label style ={{ margin: '20px 10px 10px 10px' }}>
                        <input type="radio" value="Due" 
                                checked={selectedOption === 'Due'} 
                                onChange={this.handleOptionChange} />
                            &nbsp;Due
                        </label>
                        <label style ={{ margin: '20px 10px 10px 10px' }}>
                            <input type="radio" value="Overdue" 
                                    checked={selectedOption === 'Overdue'} 
                                    onChange={this.handleOptionChange} />
                            &nbsp;Overdue
                        </label>
                        <label style ={{ margin: '20px 10px 10px 10px' }}>
                            <input type="radio" value="DueOrOverdue" 
                                    checked={selectedOption === 'DueOrOverdue'} 
                                    onChange={this.handleOptionChange} />
                            &nbsp;Due Or Overdue
                        </label>
                        <label style ={{ margin: '20px 10px 10px 10px' }}>
                                <div className="row">
                                    <div className="col" style= { {"width": 250}}>
                                        <input type="radio" value="DueOrUpcoming" 
                                                checked={selectedOption === 'DueOrUpcoming'} 
                                                onChange={this.handleOptionChange} />
                                        &nbsp;Due Or Upcoming in:
                                    </div>
                                    <div className="col" style ={{ marginTop: '-10px', marginLeft: '-40px' }}>
                                    <Dropdown
                                        placeholder='Select Duration'
                                        name='duration'
                                        width="200px"
                                        fluid
                                        search
                                        selection
                                        clearable
                                        onChange={this.handleDropDownChange}
                                        options={durationList}
                                        value={duration}
                                    />
                                </div>
                                </div>
                        </label>
                        <label style ={{ margin: '20px 10px 10px 10px' }}>
                                <div className="row">
                                    <div className="col" style= { {"width": 160}}>
                                        <input type="radio" value="Upcoming" 
                                                checked={selectedOption === 'Upcoming'} 
                                                onChange={this.handleOptionChange} />
                                        &nbsp;Upcoming in:
                                    </div>
                                    <div className="col" style ={{ marginTop: '-10px', marginLeft: '-40px' }}>
                                    <Dropdown
                                        placeholder='Select Duration'
                                        name='durationUpcoming'
                                        width="200px"
                                        fluid
                                        search
                                        selection
                                        clearable                                        
                                        onChange={this.handleDropDownChange}
                                        options={durationList}
                                        value={durationUpcoming}
                                    />
                                </div>
                                </div>
                        </label>
                        <label style ={{ margin: '20px 10px 10px 10px' }}>
                            <input type="radio" value="Completed" 
                                    checked={selectedOption === 'Completed'} 
                                    onChange={this.handleOptionChange} />
                            &nbsp;Completed
                        </label>
                        <label style ={{ margin: '20px 10px 10px 10px' }}>
                            <input type="radio" value="All" 
                                    checked={selectedOption === 'All'}
                                    onChange={this.handleOptionChange} />
                            &nbsp;All
                        </label>
                    </div>
                </div>
                { 
                    showGrid? 
                    <div style={{ height: '100%', width: '100%' }}>
                        <TorGrid
                            setAgGridAPI = {this.setAgGridAPI}
                            isHideDateInputs={true}
                            domLayout="normal"

                            rowData={gridDataFiltered}
                            rowClassRules= {{
                                "due-warning" :  "data?.due === true",
                                "overdue-warning" : 'data?.overDue === true'
                            }}
                            className="ag-theme-alpine"
                            rowSelection="multiple"
                            groupDefaultExpanded = {-1}
                            groupIncludeTotalFooter={false} 
                            groupBy='name'

                            guikey="taskInstance"
                            gridIdentityColumn="id"
                            gridLinkColumn="Item"
                            //gridApproveColumn = "Changes"
                            handleRowSelected={this.handleRowSelected}
                            //isApprover = {isApprover && inApproval}
                            approve = {this.approve}
                            reject = {this.reject}
                            showReject = {true} 

                            isRowSelectable={ (node) => { 
                                return false }}
                        />                        
                        
                    </div>:<div><b>Task Instance - No rows to display</b></div>
                }
                <Prompt when={this.state.isOpenGrid === false && JSON.stringify(existingTaskInstance) !== JSON.stringify(newTaskInstance)}
                    message="Do you want to discard changes?"/>
                <ModalForm modalType={modalType}
                    modalVisible={modalVisible}
                    modalHeading={modalHeading}
                    onClickAway={() => this.closeModal()}
                    onClickClose={() => this.closeModal()}
                    onClickYes={() => this.handleModalChange()}
                />
            </div>
        );
    }
}

export default TaskInstanceDashboard;